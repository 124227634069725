import { render, staticRenderFns } from "./DiscountLabels.vue?vue&type=template&id=59d4b84d&"
import script from "./DiscountLabels.vue?vue&type=script&lang=ts&"
export * from "./DiscountLabels.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductBadge: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/19/a/NuxtApp/components/product/Badge.vue').default})
