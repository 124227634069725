// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/defaultConfig.stub.js
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './components/**/*.{js,vue,ts}',
    // './composables/**/*.{js,ts}',
    './composables/ui/useBackdropOverlay.ts',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    // './plugins/**/*.{js,ts}',
  ],
  safelist: [
    'hover:no-underline',
  ],
  theme: {
    customPercentSpacing: {
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      '9/16': '56.25%',
      '1/24': '4.166667%',
      '2/24': '8.333333%',
      '3/24': '12.5%',
      '4/24': '16.666667%',
      '5/24': '20.833333%',
      '6/24': '25%',
      '7/24': '29.166667%',
      '8/24': '33.333333%',
      '9/24': '37.5%',
      '10/24': '41.666667%',
      '11/24': '45.833333%',
      '12/24': '50%',
      '13/24': '54.166667%',
      '14/24': '58.333333%',
      '15/24': '62.5%',
      '16/24': '66.666667%',
      '17/24': '70.833333%',
      '18/24': '75%',
      '19/24': '79.166667%',
      '20/24': '83.333333%',
      '21/24': '87.5%',
      '22/24': '91.666667%',
      '23/24': '95.833333%',
      full: '100%',
    },
    customScreenXSpacing: {
      '10vw': '10vw',
      '20vw': '20vw',
      '30vw': '30vw',
      '40vw': '40vw',
      '50vw': '50vw',
      '60vw': '60vw',
      '70vw': '70vw',
      '80vw': '80vw',
      '90vw': '90vw',
      screen: '100vw',
    },
    customScreenYSpacing: {
      '10vh': '10vh',
      '20vh': '20vh',
      '30vh': '30vh',
      '40vh': '40vh',
      '50vh': '50vh',
      '60vh': '60vh',
      '70vh': '70vh',
      '80vh': '80vh',
      '90vh': '90vh',
      screen: '100vh',
    },
    screens: {
      mini: '350px',
      xsm: '425px', // Note: place extend inside will level up `md, lg...`
      sm: '640px',
      md: '768px',
      xlg: '980px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      gridTemplateColumns: {
        // Simple 16 column grid
        '13': 'repeat(13, minmax(0, 1fr))',
        '14': 'repeat(14, minmax(0, 1fr))',
        '15': 'repeat(15, minmax(0, 1fr))',
        '16': 'repeat(16, minmax(0, 1fr))',
      },
      gridColumn: {
        'span-13': 'span 13 / span 13',
        'span-14': 'span 14 / span 14',
        'span-15': 'span 15 / span 15',
        'span-16': 'span 16 / span 16',
      },
      borderWidth: {
        '5': '5px',
      },
      colors: {
        'gw-transparent': 'transparent',
        'gw-primary': '#74AC2C',
        'gw-primary-light': '#97cc53',
        'gw-primary-lightest': '#F0F3E6',
        'gw-primary-dark': '#699A2B',
        'gw-primary-darker': '#699A2B',
        'gw-primary-darkest': '#054731',
        'gw-secondary': '#000000',
        'gw-error': '#DE1717',
        'gw-success': '#2ecc71',
        'gw-warning': '#F9E938',
        'gw-grey-300': '#F2F2F2',
        'gw-grey-400': '#EFEFEF',
        'gw-grey-425': '#e2e2e2',
        'gw-grey-450': '#dedede',
        'gw-grey-500': '#96a5b3',
        'gw-grey-600': '#999999',
        'gw-grey-700': '#707070',
        'gw-grey-800': '#202020',
        'gw-grey-900': '#131313',
        black: '#131313',

        // cms theme colors
        'gw-pink': '#B31E57',
        'gw-red': '#DE1717',
        'gw-green': '#74AC2C',
        'gw-blue': '#49647F',
        'gw-turqoise': '#438B8E',
        'gw-light-pink': '#C1A7C1',
        'gw-light-yellow': '#E2CFA6',
        'gw-orange': '#F2B85C',
        'gw-dark-green': '#0D4A34',

      },
      fontFamily: {
        body: ['futura-pt', 'sans-serif'],
        heading: ['futura-pt-bold', 'sans-serif'],
      },
      fontSize: {        
        xxs: ['.5rem', { lineHeight: '.5rem' }],  // 8px / 8px
        xs: ['0.75rem', { lineHeight: '1rem' }],  // 12px / 16px
        sm: ['0.875rem', { lineHeight: '1.25rem' }],  // 14px / 16px
        base: ['1rem', { lineHeight: '1.5rem' }],  // 16px / 20px
        lg: ['1.125rem', { lineHeight: '1.75rem' }],  // 18px / 24px
        xl: ['1.25rem', { lineHeight: '1.75rem' }],  // 20px / 24px
        '2xl': ['1.5rem', { lineHeight: '2rem' }], // 24px / 32px
        '3xl': ['2rem', { lineHeight: '2.5rem' }],  // 32px / 40px
        '4xl': ['2.5rem', { lineHeight: '2.875rem' }], // 40px / 46px
        '5xl': ['2.8125rem', { lineHeight: '1' }], // 45px
        '6xl': ['3.25rem', { lineHeight: '1' }], // 52px
        '7xl': ['4.0625rem', { lineHeight: '1' }], // 65px
        '8xl': ['6rem', { lineHeight: '1' }], // 96px
        '9xl': ['8rem', { lineHeight: '1' }], // 128px
      },
      lineHeight: {
        '1.1': '1.1',
        '18px': '1.125rem',
        '20px': '1.25rem',
        '26px': '1.625rem',
      },
      letterSpacing: {
        '0.7': '0.7px',
        '1': '1px',
      },
      spacing: {
        '4.5': '1.125rem', // 18px
        '5.5': '1.375rem', // 22px
        '6.5': '1.625rem', // 26px
        '7.5': '1.875rem', // 30px
        '12.5': '3.125rem', // 50px
        '15': '3.75rem', // 60px
        '22.5': '5.625rem', // 90px
        '25': '6.25rem', // 100px
        '32.5': '8.125rem', // 130px
        '37.5': '9.375rem', // 150px
        '55': '13.75rem', // 220px
        '75': '18.75rem', // 300px
        '128': '32rem', // 
        '150': '37.5rem', // 600px
        '160': '40rem', //
        '5px': '0.3125rem',
        '9px': '0.5625rem',
        '15px': '0.9375rem',
        '25px': '1.5625rem',
        '35px': '2.1875rem',
        '45px': '2.8125rem',
      },
      zIndex: {
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
      },
      boxShadow: {
        // x:0 y:3px blur:8px 0px rgb(0 0 0 / 0.10)
        // x:0 y:5px blur:20px 0px rgb(0 0 0 / 0.15)
        sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
        DEFAULT: '0 3px 8px 0 rgb(0 0 0 / 0.1)',
        md: '0 5px 20px 0 rgb(0 0 0 / 0.15)',
        lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
        xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
        '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
        inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      },
      borderRadius: {
        'none': '0',
        'sm': '.125rem',
        DEFAULT: '.25rem',
        'lg': '.5rem',
        'xl': '.625rem',
        'full': '9999px',
      },
      width: (theme) => ({
        ...theme('customPercentSpacing'),
        ...theme('customScreenXSpacing'),
        22.5: '5.625rem', // 90px
      }),
      height: (theme) => ({
        ...theme('customPercentSpacing'),
        ...theme('customScreenYSpacing'),
        22.5: '5.625rem', // 90px
      }),
      minWidth: (theme) => ({
        ...theme('customPercentSpacing'),
        ...theme('customScreenXSpacing'),
        ...theme('spacing'),
      }),
      minHeight: (theme) => ({
        ...theme('customPercentSpacing'),
        ...theme('customScreenYSpacing'),
        ...theme('spacing'),
      }),
      maxWidth: (theme) => ({
        ...theme('customPercentSpacing'),
        ...theme('customScreenXSpacing'),
        ...theme('spacing'),
        'gw-wrapper': '1510px',
      }),
      maxHeight: (theme) => ({
        ...theme('customPercentSpacing'),
        ...theme('customScreenYSpacing'),
        ...theme('spacing'),
      }),
      padding: (theme) => ({
        ...theme('customPercentSpacing'),
      }),
      inset: (theme) => ({
        ...theme('customPercentSpacing'),
      }),
      translate: (theme, { negative }) => ({
        ...theme('customScreenXSpacing'),
        ...theme('customScreenYSpacing'),
        ...negative(theme('customScreenXSpacing')),
        ...negative(theme('customScreenYSpacing')),
      }),
    },
  },
  variants: {
    // https://v2.tailwindcss.com/docs/configuring-variants#default-variants-reference
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/typography'),
    plugin(function({ addBase, addComponents, theme }) {
      addBase({
        // Header styling
        'h1': {
          fontSize: theme('fontSize.4xl'),
        },
        'h2': {
          fontSize: theme('fontSize.3xl'),
        },
        'h3': {
          fontSize: theme('fontSize.2xl'),
        },
        'h4': {
          fontSize: theme('fontSize.xl'),
        },
        'h5': {
          fontSize: theme('fontSize.base'),
        },
        'h6': {
          fontSize: theme('fontSize.sm'),
        },
        'h1, h2, h3, h4, h5, h6': {
          fontFamily: theme('fontFamily.heading'),
        },
        'html': {
          overflowX: 'hidden',
          fontFamily: theme('fontFamily.body'),
          fontWeight: theme('fontWeight.medium'),
          fontSize: '100%',
          '-webkitFontSmoothing': 'antialiased',
          '-mozOsxFontSmoothing': 'grayscale',
          scrollBehavior: 'smooth'
        },
        // Body styling
        'body': {
          backgroundColor: theme('colors.white'),
          color: theme('colors.black'),
          fontSize: theme('fontSize.normal'),
          '-webkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
        },
        // Paragraph styling
        'a': {
          
        },
        'sup': {
          fontSize: "58.3%",
        },
        'svg': {
          display: 'inline-block'
        },
        // custom webkit scrollbar
        '::-webkit-scrollbar': {
          width: '0.625rem',// 10px 
          height: '0.625rem'
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: '#f1f1f1'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#c1c1c1'
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#b5b5b5'
        },
        '.scrollbar-invisible': {
          scrollbarWidth: 'none', // Firefox 
          '-msOverflowStyle': 'none' // IE 10+
        },
        '.scrollbar-invisible::-webkit-scrollbar': {
          width: '0 !important',
          display: 'none' // Chrome Safari
        }
      })
      // format for using theme() in combination with !important
      // borderRadius: `${theme('borderRadius.none')} !important`
      addComponents({
        '.openingstijden table tr td': {
          paddingRight: '12px'
        },
        '.menu-gradient': {
          background: `linear-gradient(90deg, ${theme('colors.gw-grey-300')} 33%, rgba(255,255,255,0) 33%)`
        },
        '.logo': {
          
        },
        '.header': {

          '.header__top-bar': {

            'a': {
              
            },
          },
          '.header__top-nav': {
          },
          '.header__mid-bar': {
          },
          '.header__global-nav': {
          },
        },
        '.breadcrumbs': {
          
        },
        '.footer': {
          '.footer__top': {
          },
          '.footer__doormat': {
          },
          '.footer__bottom': {
            
          },
        },
        '.footer-newsletter': {
          
        },
        '.page-heading': {

        },
        '.variant-option': {
        },
        '.category-tiles': {
        },
        '.card': {

          '.card__image': {
          
          },
          '.card__body': {
          
          },
          '.card__btn-wrap': {
          
          },
        },
        '.card.card--product': {
          '.card__image': {
          
          },
          '.card__body': {
          
          },
          '.card__btn-wrap': {
          
          },
        },
        '.cart-summary': {
          
        },
        '.discount-label': {
          
        },
        '.dropdown': {
          
          '.dropdown__item': {

            '.dropdown__link': {
            },
          },
        },
        '.search': {
          '.search__input': {
          },
          '.search__button': {

          },
        },
        '.filters': {
          
          '.filters__section': {

          }
        },
        '.form-input': {
          borderRadius: theme('borderRadius.full')
        },
        '.global-nav': {

          '& > .global-nav__item': {
            
           
            '&:hover': {
            },

            '.global-nav__link': {
            },
          },
        },
        '.btn': {
        },
        '.pagination': {
        },
        '.quantity-input': {
        },
        '.modal': {
          '.modal__header': {

          },
          '.modal__content': {

          },
        },
        '.lang-switcher': {
          display: 'none'
        },
        '.pc-banner': {
          
        },
        '.size-selection': {
          '.size-selection__item': {

          }
        },
        
        '.backdrop-overlay': {
          
        },
        '.overlay': {
          
        },
        '.shopping-cart': {
          
          '.shopping-cart__text': {
            display: 'none',
          },
          '.shopping-cart__icon': {
          
          },
        },
        '.search-panel': {
          
        },
        '.top-nav': {
          
        },
        '.user-menu': {
          marginLeft: 'auto',

          '.user-menu__text': {
            display: 'none',
          },
          '.user-menu__icon': {
          
          },
        },
      })
    })
  ],
}

//       addComponents({
//         '.logo': {
//           maxHeight: '',
//           maxWidth: ''
//         },
//         '.header': {
//           backgroundColor: theme('colors.white'),
//           borderBottom: '0',
//           '.header__top-bar': {
//             backgroundColor: theme('colors.gw-primary'),
//             paddingTop: '.25rem',
//             paddingBottom: '.25rem',
//             'a': {
//               color: theme('colors.black'),
//             },
//           },
//           '.header__top-nav': {
//             backgroundColor: theme('colors.gw-grey-300'),
//             color: theme('colors.black'),
//           },
//           '.header__mid-bar': {
//             backgroundColor: theme('colors.white'),
//             color: theme('colors.black'),
//           },
//           '.header__global-nav': {
//             backgroundColor: theme('colors.white'),
//             color: theme('colors.black'),

//             'ul > li': {
//               backgroundColor: theme('colors.white'),
//               color: theme('colors.black'),
//               ':hover': {
//                 backgroundColor: theme('colors.gw-grey-300'),
//               },
//             },
//           },
//         },
//         '.breadcrumbs': {
//           borderBottomWidth: '0'
//         },
//         '.footer': {
//           '.footer__top': {
//             backgroundColor: theme('colors.white'),
//             color: theme('colors.black'),

//           },
//           '.footer__doormat': {
//             backgroundColor: theme('colors.gw-grey-800'),
//             color: theme('colors.white'),
//           },
//           '.footer__bottom': {
            
//           },
//         },
//         '.page-heading': {
          
//         },
//         '.variant-option': {
          
//         },
//         '.category-tiles': {
          
//         },
//         '.card': {
//           borderBottomLeftRadius: '1rem',
//           borderBottomRightRadius: '1rem',
//         },
//         '.discount-label': {
//           borderRadius: theme('borderRadius.xl'),
//         },
//         '.search': {
//           '.search__input': {
//             borderRadius: theme('borderRadius.full'),
//           },
//         },
//         '.form-input': {
//           borderRadius: theme('borderRadius.full'),
//         },
//         '.btn': {
//           borderRadius: theme('borderRadius.full'),
//           fontWeight: '600',
//         },
//         '.pagination': {
//           borderRadius: theme('borderRadius.full'),
//           fontWeight: '600',
//         },
//         '.quantity-input': {
//           borderRadius: theme('borderRadius.full'),
//         },
//         '.modal': {
//           '.modal__header': {

//           },
//           '.modal__content': {

//           },
//         },
//         '.size-selection': {
//           '.size-selection__item': {

//           }
//         },
//         '.backdrop-overlay': {
//           backgroundColor: theme('colors.gw-primary'),
//           opacity: '.5'
//         },
//         '.overlay': {
//           backgroundColor: theme('colors.gw-secondary'),
//           opacity: '.5'
//         },
//         '.search-panel': {
//           backgroundColor: theme('colors.white'),
//           color: theme('colors.gw-primary'),
//           borderRadius: theme('borderRadius.xl'),
//         },
//         '.gw-swiper-button-prev': {
//           backgroundColor: theme('colors.gw-secondary'),
//           borderRadius: theme('borderRadius.full'),
//           opacity: '.5'
//         },
//         '.gw-swiper-button-next': {
//           backgroundColor: theme('colors.gw-secondary'),
//           borderRadius: theme('borderRadius.full'),
//           opacity: '.5'
//         },
        
//       })
//     })
//   ],
// }
