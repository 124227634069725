import { ref, useContext, computed } from '@nuxtjs/composition-api'
import { CustomerAccount } from '~/types/customer'

export enum AccountLabels {
  orderCount = 'orderCount',
  wishlistCount = 'wishlistCount'
}

const account = ref<CustomerAccount>({})
const cacheTime = 1000 * 60 * 15

export const useAccount = () => {
  const { i18n } = useContext()

  const menu = computed(() => [
    {
      label: i18n.t('account.menu.accountOverview'),
      routeName: 'account-overview',
    },
    // {
    //   label: i18n.t('account.menu.myLoyaltyPoints'),
    //   routeName: 'account-loyalities',
    // },
    {
      label: i18n.t('account.menu.orderStatus'),
      routeName: 'account-purchases',
    },
    // {
    //   label: i18n.t('account.menu.myPurchases'),
    //   routeName: 'account-transactions',
    // },
    {
      label: i18n.t('account.menu.myDetails'),
      routeName: 'account-details',
    },
    // {
    //   label: i18n.t('account.menu.myGiftCards'),
    //   routeName: 'account-funds',
    // },
  ])

  const getAccountItem = (label: AccountLabels) => {
    return account.value[label] || null
  }

  const removeAccountItem = (label: AccountLabels) => {
    const item = getAccountItem(label)
    if (item) {
      delete account.value[label]
    }
  }

  const setAccountItem = (label: AccountLabels, value: string | number) => {
    const item = getAccountItem(label)
    if (item) removeAccountItem(label)
    account.value[label] = { cacheDate: new Date().getTime(), value, label }
  }

  const isCachedData = (label: AccountLabels) => {
    const currentTimeStamp = new Date().getTime()
    const accountItem = getAccountItem(label)
    return accountItem && accountItem.cacheDate && ((accountItem.cacheDate + cacheTime) > currentTimeStamp)
  }

  return {
    account,
    menu,

    setAccountItem,
    removeAccountItem,
    isCachedData
  }
}
