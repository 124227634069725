import { render, staticRenderFns } from "./MenuDesktop.vue?vue&type=template&id=059000dc&scoped=true&"
import script from "./MenuDesktop.vue?vue&type=script&lang=ts&"
export * from "./MenuDesktop.vue?vue&type=script&lang=ts&"
import style0 from "./MenuDesktop.vue?vue&type=style&index=0&id=059000dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059000dc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/19/a/NuxtApp/components/base/Icon.vue').default,AppHeaderNavPopover: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/19/a/NuxtApp/components/app/header/NavPopover.vue').default,AppHeaderNavItem: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/19/a/NuxtApp/components/app/header/NavItem.vue').default,BaseLink: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/19/a/NuxtApp/components/base/Link.vue').default,AppHeaderNavPopoverGroup: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/19/a/NuxtApp/components/app/header/NavPopoverGroup.vue').default})
