import { AxiosInstance, AxiosResponse } from 'axios'
import { ApiResult } from '~/types/common'
import { Customer, ChangePasswordParams } from '@/types/customer'
import { convertCustomer } from '~/lib/api/deserializers/customer'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/customer'

  return {
    async getCustomer(): Promise<any> {
      const response: AxiosResponse = await instance.get(`/${base}`)
      if (!response?.data) return null
      return convertCustomer(response.data)
    },

    async setDefaultStore(storeId: number): Promise<boolean> {
      const response: AxiosResponse = await instance.put(`/${base}/store`, {
        storeId,
      })
      return !!response.data?.Success
    },

    async validatePhone(phoneNumber: string): Promise<any> {
      const response: AxiosResponse = await instance.get(
        `/${base}/validate-phone`,
        {
          params: {
            phoneNumber,
          },
        }
      )
      return response.data?.Data
    },

    async updateCustomer(customer: Customer): Promise<string | null> {
      const data: any = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        middleName: customer.middleName,
        gender: customer.gender,
        isOptIn: customer.isOptIn,
        mobileNumber: customer.mobileNumber,
        phoneNumber: customer.phoneNumber,
        birthDate: customer.dateOfBirth,
        languageCode: customer.languageCode,
      }
      if (customer.addresses) {
        const addr = customer.addresses[0]
        data.address = {
          street: addr.street,
          postalCode: addr.postalCode,
          houseNumber: addr.houseNumber,
          houseNumberExtension: addr.houseNumberExtension,
          city: addr.city,
          countryCode: addr.countryCode,
        }
      }

      const response: AxiosResponse = await instance.put(
        `/${base}?refreshToken=true`,
        data
      )
      return response.data?.Token
    },

    // async getLogicsticStore(): Promise<Store | null> {
    //   const response: AxiosResponse = await instance.get('/api/bouwmaat/customer/logistics-store')
    //   if (!response.data?.Success) return null

    //   return convertStore(response.data.Store)
    // },

    async changePassword(
      changePassword: ChangePasswordParams
    ): Promise<ApiResult<boolean>> {
      const response: AxiosResponse = await instance.post(
        `/${base}/change-password`,
        changePassword
      )

      if (!response.data.Success) return { message: response.data?.Message }

      return { data: response.data?.Success }
    },
  }
}
