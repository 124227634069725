import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ed093b74 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _22aac76e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _43facc88 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _18088bc5 = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _9b1189f8 = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _faeae75c = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _75838152 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _605cb0ee = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _2d9ff2a4 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _848d78c4 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _af05b80c = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _23428217 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _b0ab865a = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _613b2a93 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _72fdca4b = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _c34f877e = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _1ad5ea9a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _829b2e06 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _54997dc0 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _09cd9176 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _b46a96cc = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _f159cf0a = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _27414f74 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _3ccf2c5c = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _2815cc33 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _26306e30 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _60a214a7 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _523582c5 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _33b3d163 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _3e73c81a = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _3074f5be = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _5354937f = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _308d8ce5 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _def019c4 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _4089d31c = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _4d6694cb = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _aaf82b46 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _e665a31c = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _11d14588 = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _cfb91352 = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _864e78ba = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _3aa195b1 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _04530e1a = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _524b0434 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _2b9596f4 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _92f586cc = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _6e9e8872 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _75879aaa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a4e67790 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _ed093b74,
    children: [{
      path: "",
      component: _22aac76e,
      name: "account___nl"
    }, {
      path: "details",
      component: _43facc88,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _18088bc5,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _9b1189f8,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _faeae75c,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _75838152,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _605cb0ee,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _2d9ff2a4,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _848d78c4,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _af05b80c,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _23428217,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _b0ab865a,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _613b2a93,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _72fdca4b,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _c34f877e,
    name: "product-lister-actie___nl"
  }, {
    path: "/cart",
    component: _1ad5ea9a,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _829b2e06,
    children: [{
      path: "",
      component: _54997dc0,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _09cd9176,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _b46a96cc,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _f159cf0a,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _27414f74,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _3ccf2c5c,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _2815cc33,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _26306e30,
    name: "icon___nl"
  }, {
    path: "/inspiratie",
    component: _60a214a7,
    name: "blog-category___nl"
  }, {
    path: "/login",
    component: _523582c5,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _33b3d163,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _3e73c81a,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _3074f5be,
    name: "forgot-password___nl"
  }, {
    path: "/wishlist",
    component: _5354937f,
    name: "wishlist___nl"
  }, {
    path: "/blocks/demo",
    component: _308d8ce5,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _def019c4,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _4089d31c,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _4d6694cb,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _aaf82b46,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/inspiratie/post/:slug?",
    component: _e665a31c,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _11d14588,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _cfb91352,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _864e78ba,
    name: "product-detail-slug___nl"
  }, {
    path: "/vestigingen/:slug?",
    component: _3aa195b1,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _04530e1a,
    name: "search-slug___nl"
  }, {
    path: "/inspiratie/*",
    component: _524b0434,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _2b9596f4,
    name: "blog-tag-all___nl"
  }, {
    path: "/m/*",
    component: _92f586cc,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _6e9e8872,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _75879aaa,
    name: "index___nl"
  }, {
    path: "/*",
    component: _a4e67790,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
