import { Context } from '@nuxt/types'
import { AxiosInstance, AxiosResponse } from 'axios'
import { Form } from '~/types/form'

export default function (instance: AxiosInstance, { i18n }: Context) {
  const base = '/api/form'
  const culture = i18n.localeProperties.iso ?? 'nl-BE'

  return {
    async getForm(id: string): Promise<Form | null> {
      const response: AxiosResponse = await instance.get(
        `${base}/${id}/${culture}`
      )
      if (!response.data.Form) return null

      const form = response.data.Form
      return {
        id: form.ID,
        data: form.Data,
        submitText: form.SubmitText,
        submitUrl: form.SubmitUrl,
        title: form.Title,
        text: form.Text,
      }
    },

    async submitForm(
      id: string,
      title: string,
      data: string,
      formJson: string
    ): Promise<{
      successUrl: string
    } | null> {
      const response: AxiosResponse = await instance.post(`${base}/${id}`, {
        title,
        data,
        formJson,
        culture,
      })
      if (!response.data.Success) return null
      return {
        successUrl: response.data.SuccessUrl,
      }
    },
  }
}

/*
  this.apiSlug = "/settings/forms/"+self.ID+"/test";
  let obj = {
    "Title":this.Title,
    "Data":data,
    "FormJson":json,
    "Culture":this.Culture
  }
*/
