var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discount-label flex justify-center items-center max-w-full cursor-default",class:{
    'text-xs px-1 py-0.5 rounded': _vm.size === 'xs' && _vm.label.key !== 'alcoholhoudend',
    'text-sm px-2 py-1 rounded-lg min-w-[64px]': _vm.size === 'sm' && _vm.label.key !== 'alcoholhoudend',
    'text-base p-2 rounded': _vm.size === 'md' && _vm.label.key !== 'alcoholhoudend',
    'py-3 px-4 text-lg rounded': _vm.size === 'lg' && _vm.label.key !== 'alcoholhoudend',
    'py-4 px-6 rounded': _vm.size === 'xl' && _vm.label.key !== 'alcoholhoudend',
    'py-0 px-0': _vm.label.key === 'alcoholhoudend',
  },style:({
    backgroundColor: _vm.backgroundColor,
  }),attrs:{"title":_vm.labelText}},[(_vm.label.key==='alcoholhoudend')?_c('span',{staticClass:"truncate",class:{
        'font-medium': _vm.size === 'xs'||_vm.size === 'sm'||_vm.size === 'md',
        'font-bold': _vm.size === 'lg'||_vm.size === 'xl',
    }},[_c('img',{attrs:{"width":"64","height":"auto","src":require("@/assets/images/nix18.png"),"alt":"nix18"}})]):_c('span',{staticClass:"truncate",class:{
      'font-medium': _vm.size === 'xs'||_vm.size === 'sm'||_vm.size === 'md',
      'font-bold': _vm.size === 'lg'||_vm.size === 'xl',
    },style:({
      color: _vm.textColor,
    })},[_vm._v("\n    "+_vm._s(_vm.labelText)+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }