var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"mx-auto max-w-gw-wrapper flex-row md:flex justify-between items-center text-lg min-h-15"},_vm._l((_vm.mainUsps),function(item){return _c('li',{key:item.name,staticClass:"flex justify-center"},[_c('BaseIcon',{staticClass:"text-gw-primary w-3.5 h-6 mr-2",attrs:{"name":"gw-check"}}),_vm._v(" "),_c(item.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"hover:underline"},'component',
        item.alternateUrl
          ? {
              href: item.alternateUrl,
            }
          : {
              to: _vm.localePath(
                item.url
                  ? {
                      name: 'all',
                      params: { pathMatch: item.url },
                    }
                  : {
                      name: 'index',
                    }
              ),
            }
      ,false),[_vm._v("\n      "+_vm._s(item.title)+"\n    ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }