var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-newsletter grid grid-cols-12 md:gap-9 bg-gw-primary-lightest p-9 md:p-20 rounded-xl ote"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-span-12 md:col-span-6"},[_c('BaseForm',{ref:"formEl",staticClass:"grid grid-cols-12 gap-4",attrs:{"model":_vm.formFields,"rules":_vm.rules}},[_c('BaseFormItem',{staticClass:"col-span-12 md:col-span-6",attrs:{"label":_vm.$t('userInfo.firstName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"type":"text","placeholder":"Voornaam...","status":status},on:{"change":touch},model:{value:(_vm.formFields.firstName),callback:function ($$v) {_vm.$set(_vm.formFields, "firstName", $$v)},expression:"formFields.firstName"}})]}}])}),_vm._v(" "),_c('BaseFormItem',{staticClass:"col-span-12 md:col-span-6",attrs:{"label":_vm.$t('userInfo.lastName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"type":"text","placeholder":"Achternaam...","status":status},on:{"change":touch},model:{value:(_vm.formFields.lastName),callback:function ($$v) {_vm.$set(_vm.formFields, "lastName", $$v)},expression:"formFields.lastName"}})]}}])}),_vm._v(" "),_c('BaseFormItem',{staticClass:"col-span-12",attrs:{"label":_vm.$t('userInfo.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.status;
var touch = ref.touch;
return [_c('BaseInput',{attrs:{"type":"text","placeholder":"Email address...","status":status},on:{"change":touch},model:{value:(_vm.formFields.email),callback:function ($$v) {_vm.$set(_vm.formFields, "email", $$v)},expression:"formFields.email"}})]}}])}),_vm._v(" "),_c('div',{staticClass:"col-span-12"},[_c('BaseButton',{attrs:{"type":"submit","loading":_vm.submitLoading,"disabled":!_vm.emailCorrect},on:{"click":_vm.onSubmit}},[_vm._v("\n          "+_vm._s(_vm.submitText)+"\n        ")]),_vm._v(" "),_c('AppNoty',{attrs:{"show":_vm.isSubmitFail,"text":_vm.submitFailMessage,"status":"error"},on:{"update:show":function($event){_vm.isSubmitFail=$event}}})],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-12 md:col-span-6"},[_c('h3',{staticClass:"font-bold mb-5 text-gw-primary text-3xl"},[_vm._v("\n      Meld je aan en ontvang onze nieuwsbrief!\n    ")]),_vm._v(" "),_c('p',{staticClass:"font-light mb-5"},[_vm._v("\n      Schrijf je nu in voor de G&W nieuwsbrief en ontvang gratis\n      exclusieve aanbiedingen, tips en inspiratie. Zo blijf je altijd op de\n      hoogte!"),_c('br'),_c('a',{attrs:{"href":"/privacy"}},[_vm._v("Privacy en voorwaarden")])])])}]

export { render, staticRenderFns }