import { AxiosInstance, AxiosResponse } from 'axios'
import {
  Cart,
  CartLineChange,
  CartLineNote,
  Shipment,
  CartPayment,
  CreateOrderRequest,
  DeliveryOptions,
} from '@/types/cart'
import { CustomerAddress, Customer } from '~/types/customer'
import { convertCart } from '~/lib/api/deserializers/cart'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/cart'

  const setDelivery = async (payload: any, deliveryOptions?: DeliveryOptions): Promise<Cart | null> => {
    const deliveryPackage = deliveryOptions?.package
    const response: AxiosResponse = await instance.post(`/${base}/delivery`, {
      ...payload,
      useAsInvoiceAddress: deliveryOptions?.useAsInvoiceAddress,
      packageCarrierCode: deliveryPackage?.carrierCode,
      packageServiceLevelTimeCode: deliveryPackage?.serviceLevelTimeCode,
      packageServiceLevelOtherCode: deliveryPackage?.serviceLevelOtherCode,
    })

    if (!response.data) return null
    return convertCart(response.data)
  }

  const setAddress = async (payload: any, deliveryOptions?: DeliveryOptions): Promise<Cart | null> => {
    const response: AxiosResponse = await instance.post(`/${base}/address`, {
      ...payload,
      useAsInvoiceAddress: deliveryOptions?.useAsInvoiceAddress
    })

    if (!response.data) return null
    return convertCart(response.data)
  }

  return {
    async getCart(): Promise<Cart | null> {
      // Add timestamp to avoid browser history router back use api cache
      const response: AxiosResponse = await instance.get(`/${base}?timestamp=${new Date().getTime()}`)
      if (!response.data) return null
      return convertCart(response.data)
    },

    async changeCartLines(changes: CartLineChange[]): Promise<Cart | null> {
      const response: AxiosResponse = await instance.post(
        `/${base}/lines/batch`,
        {
          changes,
        }
      )
      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    async emptyCart(): Promise<Cart | null> {
      const response: AxiosResponse = await instance.delete(`/${base}/lines`)
      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    setDeliveryAddress(
      addressId: number | string,
      deliveryOptions?: DeliveryOptions
    ): Promise<Cart | null> {
      return setAddress({
        deliveryAddressId: addressId,
      }, deliveryOptions)
    },

    setAlternativeAddress(
      address: CustomerAddress,
      deliveryOptions?: DeliveryOptions
    ): Promise<Cart | null> {
      return setAddress({
        alternativeAddress: address,
      }, deliveryOptions)
    },

    setDeliveryByDeliveryAddress(
      addressId: number | string,
      deliveryOptions?: DeliveryOptions
    ): Promise<Cart | null> {
      return setDelivery({
        method: 'CustomerAddress',
        deliveryAddressId: addressId,
      }, deliveryOptions)
    },

    setDeliveryByAlternativeAddress(
      address: CustomerAddress,
      deliveryOptions?: DeliveryOptions
    ): Promise<Cart | null> {
      return setDelivery({
        method: 'AlternativeAddress',
        alternativeAddress: address,
      }, deliveryOptions)
    },

    setDeliveryByPickupLocation(
      address: CustomerAddress,
      deliveryOptions?: DeliveryOptions,
      method?: string,
    ): Promise<Cart | null> {
      return setDelivery({
        method, // 'AlternativeAddress', // 'PickupLocation',
        alternativeAddress: address,
      }, deliveryOptions)
    },
    

    async setPickup(
      storeId: number | string,
      deliveryOptions?: DeliveryOptions
    ): Promise<Cart | null> {
      const deliveryPackage = deliveryOptions?.package
      const response: AxiosResponse = await instance.post(`/${base}/store`, {
        storeId,
        packageCarrierCode: deliveryPackage?.carrierCode,
        packageServiceLevelTimeCode: deliveryPackage?.serviceLevelTimeCode,
        packageServiceLevelOtherCode: deliveryPackage?.serviceLevelOtherCode,
      })
      if (!response?.data) {
        return null
      }
      return convertCart(response.data)
    },

    async setShipment(payload: Shipment): Promise<Cart | null> {
      const response: AxiosResponse = await instance.post(
        `${base}/shipment`,
        payload
      )
      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    // async setPointsToRedeem(pointsToRedeem: number): Promise<Cart | null> {
    //   const response: AxiosResponse = await instance.post(
    //     `api/bouwmaat/cart/points`,
    //     { pointsToRedeem }
    //   )
    //   if (!response.data.Success) return null
    //   return convertCart(response.data.Cart)
    // },

    async setPaymentMethod(payment: CartPayment): Promise<Cart | null> {
      const response: AxiosResponse = await instance.post(
        `${base}/payment`,
        {
          method: payment.method,
          data: JSON.stringify({ issuer: payment.bank }),
          phoneNumber:payment.phoneNumber,
          birthDate:payment.birthDate,
        }
      )
      if (!response.data.CartLines) return null
      return convertCart(response.data)
    },

    async createOrder(request?: CreateOrderRequest): Promise<[Cart | null, any | null]> {
      const response: AxiosResponse = await instance.post(
        `${base}/confirm`,
        request
      )
      // console.log('createOrder')
      // console.log(response.data)
      if (!response.data.PaymentUrl) return [null, response.data]
        
      return [convertCart(response.data.Cart), response.data]
    },

    async getCartShareKey(): Promise<any> {
      const response: AxiosResponse = await instance.post(`/${base}/share`)
      if (!response.data) return null
      return response.data
    },

    async setCartByShareKey(shareKey: string): Promise<any> {
      const response: AxiosResponse = await instance.post(
        `/${base}/use-share`,
        {
          shareKey
        }
      )
      if (!response.data) return null
      return convertCart(response.data.Cart)
    },

    async addVoucher(voucherId: number): Promise<Cart | null> {
      const response: AxiosResponse = await instance.post(`/${base}/vouchers`, {
        voucherId
      })

      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    async addCoupon(couponId: number): Promise<Cart | null> {
      const response: AxiosResponse = await instance.post(`/${base}/vouchers`, {
        couponId
      })

      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    async removeVoucher(voucherId: number): Promise<Cart | null> {
      const response: AxiosResponse = await instance.delete(
        `/${base}/vouchers`,
        {
          params: {
            voucherId,
          },
        }
      )
      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    async removeCoupon(couponId: number): Promise<Cart | null> {
      const response: AxiosResponse = await instance.delete(
        `/${base}/vouchers`,
        {
          params: {
            couponId,
          },
        }
      )
      if (!response.data.Success) return null
      return convertCart(response.data.Cart)
    },

    async setCartCustomer(customer: Customer): Promise<any> {
      const response: AxiosResponse = await instance.post(
        `/${base}/customer`,
        customer
      )
      if (!response.data) return null
      return convertCart(response.data.Cart)
    },
    async setCartLineNotes(changes: CartLineNote[]):  Promise<any> {
      const response: AxiosResponse = await instance.post(
        `/${base}/lines/notes`,
        {
          changes
        }
      )
      if (!response.data) return null
      return convertCart(response.data.Cart)
    }
  }
}
