var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppHeaderNavPopoverGroup',{attrs:{"group-class":"justify-between"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onShowPopover = ref.onShowPopover;
return [_c('ul',{staticClass:"-ml-2.5 flex items-center justify-between xl:-ml-4 xl:-mr-4 mr-2"},[(_vm.categories && _vm.categories.length)?_vm._l((_vm.categories),function(item,index){return _c('li',{key:("categories-" + index)},[_c('AppHeaderNavPopover',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('NuxtLink',{staticClass:"relative font-medium flex items-center h-12.5 px-2.5 xl:px-4 hover:bg-white",attrs:{"to":item.code === 'ACTIES'
                      ? _vm.localePath({
                          name: 'all',
                          params: { pathMatch: 'actie' },
                        })
                      : _vm.localePath(
                          item.url
                            ? {
                                name: 'product-lister-all',
                                params: { pathMatch: item.url },
                              }
                            : {
                                name: 'index',
                              }
                        ),"data-testid":"menu-item"}},[_c('span',{staticClass:"block truncate"},[_vm._v(_vm._s(item.name))])])]}},{key:"default",fn:function(){return [_c('div',{staticClass:"md:px-5 md:py-2 mx-auto max-w-gw-wrapper "},[(item.children && item.children.length)?_c('ul',{staticClass:"bg-white text-black capitalize mt-px px-5 2xl:px-0 py-5 -mx-5 min-h-[240px] first-line:first-letter:capitalize columns-4 gap-4 lg:columns-6 lg:gap-8"},_vm._l((item.children),function(subItem,subIndex){return _c('li',{key:("sub-" + subIndex),staticClass:"break-inside-avoid-column mb-7"},[_c('NuxtLink',{staticClass:"font-medium flex items-center hover:text-gw-secondary mb-1",attrs:{"to":_vm.localePath(
                          subItem.url
                            ? {
                                name: 'product-lister-all',
                                params: { pathMatch: subItem.url },
                              }
                            : {
                                name: 'index',
                              }
                        ),"data-testid":"menu-sub-item-title"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n                        "+_vm._s(subItem.name)+"\n                      ")])]),_vm._v(" "),(subItem.children && subItem.children.length)?_c('ul',[_vm._l((subItem.children.slice(0, 7)),function(subSubItem,subSubIndex){return _c('li',{key:("sub-sub-" + subSubIndex)},[_c('NuxtLink',{staticClass:"font-light hover:text-gw-secondary",attrs:{"to":_vm.localePath(
                              subSubItem.url
                                ? {
                                    name: 'product-lister-all',
                                    params: { pathMatch: subSubItem.url },
                                  }
                                : {
                                    name: 'index',
                                  }
                            ),"data-testid":"menu-sub-item"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n                            "+_vm._s(subSubItem.name)+"\n                          ")])])],1)}),_vm._v(" "),(subItem.children.length > 7)?_c('li',[_c('NuxtLink',{staticClass:"font-light flex items-center hover:text-gw-secondary",attrs:{"to":_vm.localePath(
                              subItem.url
                                ? {
                                    name: 'product-lister-all',
                                    params: { pathMatch: subItem.url },
                                  }
                                : {
                                    name: 'index',
                                  }
                            ),"data-testid":"menu-sub-item-more"}},[_c('BaseIcon',{staticClass:"w-2.5 h-2.5 mr-2",attrs:{"name":"gw-arrow-forward"}}),_vm._v("\n                        "+_vm._s(_vm.$t('text.allCategories'))+"\n                      ")],1)],1):_vm._e()],2):_vm._e()],1)}),0):_vm._e()])]},proxy:true}],null,true)})],1)}):_vm._e(),_vm._v(" "),(_vm.mainMenu.length)?_vm._l((_vm.mainMenu),function(item,index){return _c('li',{key:("mainMenuLeft-" + index),staticClass:"global-nav__item hover:bg-gw-white min-w-0 relative w-max"},[_c('AppHeaderNavPopover',{attrs:{"placement":"left"},on:{"show":onShowPopover},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                            var active = ref.active;
return [_c('AppHeaderNavItem',_vm._b({attrs:{"label":item.name,"active":active,"icon-visible":!!(item.children && item.children.length)}},'AppHeaderNavItem',
                  item.alternateUrl
                    ? {
                        href: item.alternateUrl,
                      }
                    : {
                        to: _vm.localePath({
                          name: 'all',
                          params: { pathMatch: item.url },
                        }),
                      }
                ,false))]}},{key:"default",fn:function(){return [(item.children && item.children.length)?_c('ul',{staticClass:"absolute top-full z-30 w-40 rounded-0 shadow-lg bg-white inset-x-0"},_vm._l((item.children),function(nestedItem,nestedIndex){return _c('li',{key:("subMenu-" + nestedIndex),staticClass:"break-inside-avoid-column mb-3 ml-2"},[_c('BaseLink',{staticClass:"flex items-center py-3 px-1",attrs:{"path":nestedItem.alternateUrl ||
                      _vm.localePath({
                        name: 'all',
                        params: { pathMatch: nestedItem.url },
                      }),"label":nestedItem.name}})],1)}),0):_vm._e()]},proxy:true}],null,true)})],1)}):_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }