import { convertProduct } from './product'
import { convertStore } from './store'
import { convertCustomer, convertCustomerAddress } from './customer'
import { Cart, CartLine, ShippingMethod } from '@/types/cart'
import { Coupon, Voucher, VoucherKind } from '~/types/promotion'
import { MAX_QUANTITY } from '~/composables/product/useProduct'
import { ProductType } from '~/types/product'
import {numberFormat} from "~/lib/api/deserializers/product.meili";


export const convertCartLine = (
  data: any,
  lineExtraFields?: any,
  productExtraFields?: any
): CartLine => {
  const product = convertProduct(
    data.Product,
    Object.assign({ url: data.ProductUrl }, productExtraFields)
  )

  const result: CartLine = {
    id: data.Id,
    productId: data.ProductId,
    quantity: data.Quantity,
    img: data.ImageUrl || "",
    productTitle: data.ProductTitle,
    // Important:
    // ASPOS cart line has no field to indicate applied price per product.
    // We use total/quantity to indicate applied price.
    // Product.price can get original price
    priceInclTax: parseFloat((data.TotalInclTax / data.Quantity).toFixed(2)),
    priceExclTax: parseFloat((data.TotalExclTax / data.Quantity).toFixed(2)),
    totalInclTax: data.TotalInclTax,
    totalExclTax: data.TotalExclTax,
    discountInclTax: data.DiscountAmountInclTax,
    discountExclTax: data.DiscountAmountExclTax,

    derived: data.AsposDerived,

    product,
  }

  

  if (!result.derived) {
    result.isValid = result.product?.isValidInCart ?? false
  }

  const oldPriceInclTax = product.oldPriceInclTax ?? product.priceInclTax ?? 0
  const oldPriceExclTax = product.oldPriceExclTax ?? product.priceExclTax ?? 0
  // Todo: too tricky here, need ASPOS exact applied price of per product
  result.priceChanged =
    (result.priceInclTax ?? 0) + 0.01 < (oldPriceInclTax ?? 0)
  if (result.priceChanged) {
    result.oldPriceInclTax = oldPriceInclTax
    result.oldPriceExclTax = oldPriceExclTax
  }

  result.maxQuantity = product.maxStockEnabled
    ? product.stockQuantity
    : MAX_QUANTITY

  result.shippingEstimate = lineExtraFields?.shippingEstimate

  return result
}

export const convertCartCoupon = (data: any): Coupon => {
  return {
    id: data.CouponId,
    name: data.CouponDescription,
  }
}

export const convertCartVoucher = (data: any): Voucher => {
  return {
    id: data.VoucherId,
    name: data.VoucherDescription,
    code: data.VoucherCode,
    kind:
      data.Controller === 'Payment' ? VoucherKind.Payment : VoucherKind.Product,
    paymentAmount: data.PaymentAmount,
  }
}

export const convertCart = (data: any): Cart | null => {
  if (!data) return null

  const cartLines: CartLine[] = []
  const voucherLines: CartLine[] = []

  data.CartLines?.forEach((line: any) => {
    const convertedLine = convertCartLine(
      line,
      {
        maxQuantity: maxOrderableQuantities?.[line.ProductId],
        shippingEstimate:
          data.CustomCartFields.PerLineShippingEstimates?.[line.Id],
      },
      data.CustomCartFields.ProductExtraFields?.[line.ProductId]
    )
    if (convertedLine.product?.type === ProductType.Voucher) {
      voucherLines.push(convertedLine)
    } else {
      cartLines.push(convertedLine)
    }
  })

  const maxOrderableQuantities = data.IsDeliveryOrder
    ? data.CustomCartFields.MaxOrderableQuantitiesDelivery
    : data.CustomCartFields.MaxOrderableQuantitiesPickup
  const cart: Cart = {
    lines: [...cartLines, ...voucherLines],
    palletLines: data.CustomCartFields.PalletCostLines?.map((line: any) =>
      convertCartLine(line)
    ),
    voucherLines,

    shippingMethod: data.IsDeliveryOrder
      ? ShippingMethod.Delivery
      : ShippingMethod.Pickup,
    shippingMethodType: data.ShippingMethodType,
    isDeliveryOrder: data.IsDeliveryOrder,
    deliveryCostExclTax: data.CustomCartFields.DeliveryCostsExclTax,
    deliveryCostInclTax: data.CustomCartFields.DeliveryCostsInclTax,
    deliveryCostPreviewExclTax:
      data.CustomCartFields.DeliveryCostsPreviewExclTax,
    deliveryCostPreviewInclTax:
      data.CustomCartFields.DeliveryCostsPreviewInclTax,
    palletCostExclTax: data.CustomCartFields.PalletCostsExclTax,
    palletCostInclTax: data.CustomCartFields.PalletCostsInclTax,
    
    shippingInclTax: data.ShippingCostsInclTax,
    shippingIntegerInclTax: "",
    shippingDecimalInclTax: "",
    shippingExclTax: data.ShippingCostsExclTax,
    subTotalInclTax: data.SubTotalInclTax || 0,
    subTotalIntegerInclTax: "",
    subTotalDecimalInclTax: "",
    subTotalExclTax: data.SubTotalExclTax || 0,
    totalInclTax: data.TotalInclTax || 0,
    totalIntegerInclTax: "",
    totalDecimalInclTax: "",
    totalExclTax: data.TotalExclTax || 0,
    
    totalListPrice: data.TotalListPrice || 0,
    totalListPriceInteger: "",
    totalListPriceDecimal: "",

    discountInclTax: data.DiscountAmountInclTax,
    discountIntegerInclTax: "",
    discountDecimalInclTax: "",
    discountExclTax: data.DiscountAmountExclTax,

    pointsToRedeem: data.CustomCartFields.PointsToRedeem,

    coupons:
      data.Coupons?.map((coupon) => convertCartCoupon(coupon)) ?? undefined,

    vouchers:
      data.Vouchers?.map((voucher) => convertCartVoucher(voucher)) ?? undefined,
  }

  if (data.Customer) {
    cart.customer = convertCustomer(data.Customer)
  }

  if (data.DeliveryAddress) {
    cart.deliveryAddress = convertCustomerAddress({
      ...data.DeliveryAddress,
      Id: data.DeliveryAddressId,
    })
  }

  if (data.AlternativeDeliveryAddress) {
    cart.alternativeAddress = convertCustomerAddress(data.AlternativeDeliveryAddress)
  }

  if (data.PickupPointAddress) {
    cart.pickupPointAddress = convertCustomerAddress(data.PickupPointAddress)
  }

  if (data.InvoiceAddress) {
    cart.invoiceAddress = convertCustomerAddress(data.InvoiceAddress)
  }

  if (data.Store) {
    cart.store = convertStore({
      ...data.Store,
      Id: data.StoreId,
    })
  }

  if (data.ShipmentMethod) {
    const shipping = data.ShipmentMethod
    cart.shipping = {
      id: shipping.Id,
      price: shipping.Price,
      date: shipping.Date,
      timeFrom: shipping.Time?.From,
      timeTo: shipping.Time?.To,
    }
  }

  if (data.PaymentMethod) {
    cart.payment = {
      method: data.PaymentMethod,
    }
    if (data.PaymentData) {
      cart.payment.bank = JSON.parse(data.PaymentData).issuer
    }
  }

  return cart
}
