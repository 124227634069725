import {
  useContext,
  readonly,
  useRouter,
  ref,
  computed,
} from '@nuxtjs/composition-api'
import { useLoading } from '../ui/useLoading'
import { useAuth } from '../useAuth'
import { ProductReview } from '~/types/product'
import { RatingGroup } from '~/types/common'

export enum ReviewSorting {
  NewestFirst = 'newestFirst',
  OldestFirst = 'oldestFirst',
  MostStars = 'mostStars',
  FewestStars = 'fewestStars',
}

export const useProductReview = () => {
  const {
    app: { i18n, $api, localePath },
  } = useContext()
  const router = useRouter()
  const { ensureAuthed } = useAuth()
  const getProductReviewsLoading = useLoading()
  const addProductReviewLoading = useLoading()
  const reviews = ref<ProductReview[]>([])
  const averageRating = ref(0)
  const totalRating = ref(0)
  const totalCount = ref(0)
  const ratingGroups = ref<RatingGroup[]>([])

  const sortingOptions = computed(() =>
    Object.keys(ReviewSorting).map((item) => {
      const val = ReviewSorting[item]
      return {
        label: i18n.t(`reviews.sorting.${val}`) as string,
        value: val,
      }
    })
  )

  const goToReview = (
    productUrl: string,
    productId: number,
    productName: string,
    enabled: boolean
  ) => {
    if (!productUrl || !productId || !productName || !enabled) return
    ensureAuthed(() => {
      router.push(
        localePath({
          name: 'review-writing-slug',
          params: { slug: productUrl },
          query: {
            productId: productId.toString(),
            productName,
          },
        })
      )
    })
  }

  const getProductReviews = (productId: number, sorting?: ReviewSorting) => {
    return getProductReviewsLoading.scope(async () => {
      const result = await $api.product.getReviews({
        productId,
        sorting,
        needRatingGroups: true,
      })
      reviews.value = result?.reviews ?? []
      averageRating.value = result?.averageRating ?? 0
      totalRating.value = result?.totalRating ?? 0
      totalCount.value = result?.totalCount ?? 0
      ratingGroups.value = result?.ratingGroups ?? []
    })
  }

  const addProductReview = async (productId: number, review: ProductReview) => {
    let success = false
    await addProductReviewLoading.scope(async () => {
      success = await $api.product.addReview(productId, review)
    })
    return success
  }

  return {
    getProductReviewsLoading: readonly(getProductReviewsLoading.value),
    addProductReviewLoading: readonly(addProductReviewLoading.value),
    reviews: readonly(reviews),
    averageRating: readonly(averageRating),
    totalRating: readonly(totalRating),
    totalCount: readonly(totalCount),
    ratingGroups: readonly(ratingGroups),
    sortingOptions,

    getProductReviews,
    addProductReview,
    goToReview,
  }
}
