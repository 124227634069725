import { AxiosInstance, AxiosResponse } from 'axios'
import {
  convertLabelColorConfig,
  convertSocialLink,
} from '../deserializers/common'
import { GlobalConfig } from '~/types/common'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/common'

  return {
    async getConfig(): Promise<GlobalConfig | null> {
      const response: AxiosResponse = await instance.get(`/${base}/config`)
      if (!response.data.Success) return null

      const config = response.data.Config

      // const test = {"NL":{"NL":"Nederland"},"BE":{"NL":"België"},"DE":{"NL":"Duitsland"}}

      const result: GlobalConfig = {
        gtmEnabled: config.GtmEnabled,
        gtmSetting: config.GtmSetting,

        webStoreId: config.WebshopStoreId,
        deliveryCutoffLarge: config.DeliveryCutoffLarge,
        deliveryCutoffSmall: config.DeliveryCutoffSmall,
        pickupCutoff: config.PickupOff,
        socialLinks: config.SocialLinks?.map((item: any) =>
          convertSocialLink(item)
        ),
        specialWebNodes: {
          actie: config.SpecialWebNodes?.Actie?.[0],
          'meer=minder': config.SpecialWebNodes?.['Meer=Minder']?.[0],
          'op=op': config.SpecialWebNodes?.['Op=Op']?.[0],
          homePopular: config.SpecialWebNodes?.HomePopular?.[0],
        },
        shippingRegions: config.ShippingRegions,
        minimumFreeShippingLarge: config.MinimumFreeShippingLarge,
        minimumFreeShippingSmall: config.MinimumFreeShippingSmall,
        loyaltyCustomerCodes: config.CustomerGroups?.Loyalty,
        hideStoreNames: config.HideStores?.map((name: any) =>
          name.toLowerCase()
        ),
        stockCount: config.StockCount || 0,
        minimumPointsToRedeem: config.MinimumPointsToRedeem || 0,
        loyaltyCard: {
          optInNewCustomersByDefault:
            config.LoyaltyCard?.OptInNewCustomersByDefault,
        },

        // connect to appsettings or project setting or where-ever needed
        addQuantityCount: [
          {'value': 1, 'label': "+1"},
          {'value': 2, 'label': "+2"},
          {'value': 3, 'label': "+3"},
          {'value': 4, 'label': "+4"},
          {'value': 5, 'label': "+5"},
          {'value': 6, 'label': "+6"},
          {'value': 7, 'label': "+7"},
          {'value': 8, 'label': "+8"},
          {'value': 9, 'label': "+9"},
          {'value': 10, 'label': "+10"},
          {'value': 11, 'label': "+11"},
          {'value': 12, 'label': "+12"},
          {'value': 13, 'label': "+13"},
          {'value': 14, 'label': "+14"},
          {'value': 15, 'label': "+15"},
          {'value': 16, 'label': "+16"},
          {'value': 17, 'label': "+17"},
          {'value': 18, 'label': "+18"},
          {'value': 19, 'label': "+19"},
          {'value': 20, 'label': "+20"},
          {'value': 21, 'label': "+21"},
          {'value': 22, 'label': "+22"},
          {'value': 23, 'label': "+23"},
          {'value': 24, 'label': "+24"},
        ],
      }

      if (config.DiscountBackgroundColors) {
        result.discountBackgroundColors = Object.entries(
          config.DiscountBackgroundColors
        ).reduce((result, entry) => {
          result[entry[0].toLowerCase()] = entry[1] as string
          return result
        }, {})
      }

      if (config.ProductSpecifications) {
        result.productSpecifications = Object.entries(
          config.ProductSpecifications
        ).reduce((result, entry) => {
          result[entry[0].toLowerCase()] = entry[1] as string
          return result
        }, {})
      }

      if (config.Holidays?.length) {
        result.holidays = config.Holidays.map((date: any) => new Date(date))
      }

      if (config.DiscountLabels) {
        result.discountLabelColors = convertLabelColorConfig(
          config.DiscountLabels
        )
      }

      if (config.ProductLabels) {
        result.productLabelColors = convertLabelColorConfig(
          config.ProductLabels
        )
      }

      if (config.DeliveryCountries) {
        result.deliveryCountries = config.DeliveryCountries
      }

      return result
    },
  }
}
