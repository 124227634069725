import {
  ref,
  useContext,
  computed,
  onBeforeUnmount,
} from '@nuxtjs/composition-api'
import { useContent } from './useContent'
import { SiteBannerFlags } from '~/types/content'

export const keyOfDismissSiteBaner = 'gezondheidwinkel_dismiss_sitebanners'

const target = ref(SiteBannerFlags.ShowSiteWide)

export const useSiteBanner = () => {
  const { app } = useContext()
  const { siteBanners } = useContent()

  const getDismissedSiteBannerIds = () => {
    dismissedSiteBannerIds.value =
      app.$storage.getCookie(keyOfDismissSiteBaner) || []
  }

  const dismissedSiteBannerIds = ref(
    app.$storage.getCookie(keyOfDismissSiteBaner) || []
  )

  const filteredSiteBanners = computed(() => {
    return (
      siteBanners.value?.filter(
        (item) =>
          !dismissedSiteBannerIds.value?.includes(item.id) &&
          (item[target.value] || item.showSiteWide)
      ) || []
    )
  })

  const showSiteBanner = (flag: SiteBannerFlags) => {
    target.value = flag
  }

  const onDismissed = (id: string) => {
    app.$storage.setCookie(keyOfDismissSiteBaner, [
      ...dismissedSiteBannerIds.value,
      id,
    ])
    // note: update dismissedSiteBannerIds
    getDismissedSiteBannerIds()
  }

  const initTarget = () => {
    target.value = SiteBannerFlags.ShowSiteWide
  }

  onBeforeUnmount(() => {
    initTarget()
  })

  return {
    SiteBannerFlags,
    filteredSiteBanners,

    showSiteBanner,
    onDismissed,
  }
}
