import { useContext, useRoute, useRouter } from '@nuxtjs/composition-api'

export const useRedirect = () => {
  const { localePath, redirect, from, i18n } = useContext()
  const route = useRoute()
  const router = useRouter()

  // Note: fromRouteName would be undefined when redirect from middleware, so need an extra query.redirect to redirect back
  const fromRouteName = from.value?.name?.split('___')[0]
  const queryRedirect = route.value?.query?.redirect
  const redirectObject = queryRedirect && JSON.parse(queryRedirect as string)
  const backRouteNames = ['product-lister-all']

  const redirectBack = (locale: string = i18n.locale) => {
    if (redirectObject) {
      redirect(
        localePath(
          {
            name: redirectObject.name,
            params: redirectObject.params,
            query: redirectObject.query,
          },
          locale
        )
      )
    } else if (
      fromRouteName &&
      fromRouteName !== 'login' &&
      fromRouteName !== 'login-guest'
    ) {
      if(fromRouteName == 'cart'){
        console.log("inside cart")
        redirect(
          localePath( 'checkout'  )
        )
      }
      else if (backRouteNames.includes(fromRouteName)) {
        router.back()
        return
      }
      redirect(
        localePath(
          {
            name: fromRouteName,
            params: from.value?.params,
            query: from.value?.query,
          },
          locale
        )
      )
    } else {
      redirect(localePath({ name: 'index' }, locale))
    }
  }

  return {
    redirectBack,
  }
}
