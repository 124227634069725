// import { difference } from 'ramda'
import { convertAddress, convertLocation } from './geo'
import { convertOpeningTimes } from './time'
import {
  Store,
  OrderBy,
  PickupLocation,
  // StoreType,
} from '@/types/store'

export const convertStore = (
  data: any,
  extraFields?: Record<string, any>
): Store => {
  const convertedAddress = convertAddress(data)
  const store: Store = {
    id: data?.ID,
    title: data?.Title,
    name: data?.Description || data?.Name,
    type: data?.Type,
    location: convertLocation(data, extraFields?.noDistance),
    phoneNumber: data?.PhoneNumber,
    address: data?.PlainSummary?.replace("&nbsp;",""),
    customText1: data?.CustomText1?.replace("&nbsp;",""),
    customText2: data?.CustomText2?.replace("&nbsp;",""),
    email: data?.Email,
    openingTimes: data?.OpeningTimes && convertOpeningTimes(data.OpeningTimes),
    url: data?.Url,
    image: data?.Images?.find((image: any) => image.Type === 'FrontSide')?.Url,
    gallery: data.Images?.map((img: any, index: number) => ({
      type: 'image',
      order: img.Type === 'FrontSide' ? 0 : index + 1,
      src: img.Url,
    })),
    manager: {
      firstName: data?.BranchManager?.FirstName,
      lastName: data?.BranchManager?.LastName,
      image: data?.Images?.find((image) => image.Type === 'FrontSide')?.Url,
    },
    code: data?.Code,
    ...convertedAddress,
    onFleetId: extraFields?.Settings?.OnFleetID ?? undefined,
  }
  return store
}

export const convertStores = (payload: {
  stores: any[]
  storeUrls: Record<string, any>
  extraFields: Record<string, any>
  orderBy?: OrderBy
  noDistance?: boolean
}): Store[] => {
  const { stores/* , storeUrls, extraFields, orderBy, noDistance */ } = payload
  const normalizedStores = stores.map((store) =>
    convertStore(
      store
    )
  )
  // if (orderBy === undefined) return normalizedStores

  // switch (orderBy) {
  //   case OrderBy.Distance:
  //     normalizedStores.sort(
  //       (a, b) =>
  //         (a.location?.distanceInMeters ?? 0) -
  //         (b.location?.distanceInMeters ?? 0)
  //     )
  //     break
  //   default:
  //     normalizedStores.sort((a, b) => (a.name > b.name ? 1 : -1))
  // }

  return normalizedStores
}

// Pickup Locations
export const convertPickupLocation = (
  data: any,
): PickupLocation => {
  const location = {
    Longitude: data?.Longitude,
    Latitude: data?.Latitude
  }
  const convertedAddress = convertAddress(data)
  const store: PickupLocation = {
    id: data?.Id,
    name: data?.Name,
    phoneNumber: data?.Phone,
    email: data?.Email,
    code: data?.Code,
    location: convertLocation(location),
    ...convertedAddress,
  }
  return store
}

export const convertPickupLocations = (payload: {
  stores: any[]
}): PickupLocation[] => {
  const { stores } = payload
  const normalizedStores = stores.map((store) =>
    convertPickupLocation(
      store,
    )
  )

  return normalizedStores
}
