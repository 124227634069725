import { Context } from '@nuxt/types'
import { AxiosInstance, AxiosResponse } from 'axios'

export default function (instance: AxiosInstance, { i18n }: Context) {
  const base = '/api/newsletter/add'
  //const culture = i18n.localeProperties.iso ?? 'nl-NL'

  return {
    async submitForm(
      ListId: string,
      EmailAddress: string,
      FirstName: string,
      LastName: string,
      RecaptchaResponse: string = ''
    ): Promise<{
      success: boolean,
      reasonCode?: string
    } | null> {
      const response: AxiosResponse = await instance.post(`${base}`, {
        ListId,
        EmailAddress,
        FirstName,
        LastName,
        RecaptchaResponse
      })
      return {
        success: response.data.Success,
        reasonCode: response.data.ReasonCode
      }
    },
    isEmail(search:string):boolean
    {
        //let serchfind:boolean
        //let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
       //serchfind = regexp.test(search)
        //console.log(serchfind)
        return true
    }
  }
  
}

/*
  this.apiSlug = "/settings/forms/"+self.ID+"/test";
  let obj = {
    "Title":this.Title,
    "Data":data,
    "FormJson":json,
    "Culture":this.Culture
  }
*/
