import { clone, invertObj } from 'ramda'

export const numberArray = (from: number, to: number) => {
  if (to < from) {
    throw new Error('`from` cannot be greater than `to`')
  }
  return [...Array(to - from + 1).keys()].map((n) => n + from)
}

export const checkStartsWith = (str:string, val:string) => {
  const result = str?.startsWith(val);
  return result
}

export const trimString = (str: string, length: number) => {
  return Number(str).toString().padStart(length, '0')
}

export const encodeName = (name?: string, pattern = /[^a-zA-Z0-9-\s]/g) => {
  if (!name) return ''

  return name
    .trim()
    .toLowerCase()
    .replace(pattern, '')
    .replace(/\s/g, '-')
    .replace(/--/g, '-')
}

// encodeRichName is a function based on encodeName to keep special string
export const encodeRichName = (name: string) => {
  return encodeName(name, /[^a-zA-Z0-9àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ-\s]/g)
}
export const doubleDecodeURI = (str: string) => {
  if (!str) return ''
  return decodeURI(str)
}
export function parseToArray<T>(val: T | T[]): NonNullable<T>[] {
  if (val === undefined || val === null) return []
  return (Array.isArray(val) ? val : [val]) as NonNullable<T>[]
}
export const inDateRange = (
  startDate?: any,
  endDate?: any
): boolean => {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

  if (startDate && startDate > today) return false
  if (endDate && today > endDate) return false

  return true
}

export enum Action {
  Add = 0,
  Remove = 1,
  Replace = 2,
  Empty = 3,
}

export interface QueryAction {
  key: string
  values?: string | string[]
  action?: Action
}

export const combineQuery = (
  currenctQuery: Record<string, string | (string | null)[]>,
  queries: QueryAction | QueryAction[]
) => {
  const newQuery = clone(currenctQuery)
  const queriesArr = parseToArray(queries)

  queriesArr.forEach((item) => {
    const key = item.key
    let newValue: string[] = []
    const newQueryValues = parseToArray(newQuery[key])
    const valuesArr = parseToArray(item.values)
    const valuesArrSet = new Set(valuesArr)

    switch (item.action) {
      case Action.Remove:
        newValue = newQueryValues.filter((val) => !valuesArrSet.has(val))
        break
      case Action.Replace:
        newValue = valuesArr
        break
      case Action.Empty:
        break
      default:
        newValue = Array.from(new Set(newQueryValues.concat(valuesArr)))
    }

    if (newValue.length) {
      newQuery[key] = newValue.length > 1 ? newValue : newValue?.[0]
    } else {
      delete newQuery[key]
    }
  })

  return newQuery
}

export const highlight = (
  str: string,
  keyword: string,
  bold: boolean = true
) => {
  if (!str || !keyword.trim()) return str
  const escapeRegExp = (text: string) => {
    return text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
  }
  const normalizeQuery = keyword.trim().replace(/\s+/g, ' ')
  const regx = new RegExp(
    String.raw`(${normalizeQuery.split(/\s/).map(escapeRegExp).join('|')})`,
    'ig'
  )
  const fontWeight = bold ? 'font-bold' : 'font-normal'
  return str
    .toString()
    .replace(
      regx,
      (matchedText) => `<span class="${fontWeight}">${matchedText}</span>`
    )
}

export const getValidImage = (imgUrl: string) => {
  if (!imgUrl) return imgUrl

  return imgUrl?.indexOf('//') === 0 ? `https:${imgUrl}` : imgUrl
}

export const getValidColorHex = (str: string) => {
  if (!str) return ''
  return str.charAt(0) === '#' ? str : `#${str}`
}

export const getDecimalLength = (num: number) => {
  const numArr = num.toString().split('.')
  if (numArr.length !== 2) return 0
  return numArr[1].length
}

export const getSizeNum = (str: string) => {
  if (!str) return 0
  const num = Number(str.split(' ')[0])
  return isNaN(num) ? 0 : num
}

export const isString = (val: unknown): val is string => typeof val === 'string'

export const decodeHtml = (str: string) => {
  if (!str) return ''
  return str
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&commat;/g, '@')
    .replace(/&reg;/g, '®')
    .replace(/&copy;/g, '©')
}

export const htmlToText = (str: string) => {
  if (!str) return ''
  return str.replace(/<[^>]*>/g, '')
}

export const parseJson = (str: string): Record<string, any> | any[] | null => {
  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

export const toWeights = (arr: string[]) => {
  return arr.reduce((prev, current, index) => {
    prev[current] = index + 1
    return prev
  }, {} as Record<string, number>)
}

export const sortAndGroupByAlphabet = (data: any, prop: string) => {
  const result = data.reduce((prev: any, current: any) => {
    const alphabet = current[prop]?.[0]?.match(/[a-zA-Z]/)
      ? current[prop]?.[0]
      : '#'
    if (!prev[alphabet]) {
      prev[alphabet] = {
        alphabet,
        record: [current],
      }
    } else {
      prev[alphabet].record.push(current)
    }
    return prev
  }, {})

  return Object.values(result).sort((a: any, b: any) => {
    if (a.alphabet === '#') {
      return 1
    } else {
      return a.alphabet.localeCompare(b.alphabet, 'en', { sensitivity: 'base' })
    }
  })
}

export function getPageUrl(path: string) {
  const words = path.split('/')
  return (!!words.length && words[words.length - 1]) || ''
}

export function getPagePathUrl(path: string) {
  const words = path.split("/");
  words.pop()
  return !!words.length && words.join("/") || '';
}

export const parseQuery = (query: string) => {
  if (!query) return {}

  const queryParams = new URLSearchParams(query)
  const result: Record<string, string | string[]> = {}

  queryParams.forEach((val, key) => {
    const currentVal = result[key]
    if (!currentVal) {
      result[key] = val
      return
    }
    if (Array.isArray(currentVal)) {
      currentVal.push(val)
    } else {
      result[key] = [currentVal, val]
    }
  })

  return result
}

export const stringifyQuery = (params: Record<string, unknown>): string => {
  const queryParams = new URLSearchParams()

  const appendQuery = (k: string, v: unknown) => {
    if (v === undefined || v === null || v === '') return
    queryParams.append(k, String(v))
  }

  for (const key in params) {
    const val = params[key]
    if (Array.isArray(val)) {
      for (const item of val) {
        appendQuery(key, item)
      }
    } else {
      appendQuery(key, val)
    }
  }

  return queryParams.toString()
}

export const getImageExtension = (url: string) => {
  const extension = url.split('?')[0]?.split('.')?.pop()
  return extension ? `.${extension}` : ''
}

export const addQueryToUrl = (url: string, query: string) => {
  let newUrl = ''
  if (url.includes('?')) {
    newUrl = `${url}&${query}`
  } else {
    newUrl = `${url}?${query}`
  }
  return newUrl
}
const escapeMap = {
  "'": "\\'",
  '"': '\\"',
  '\\': '\\\\',
  '\n': '\\n',
  '\r': '\\r',
  '\t': '\\t',
  '\b': '\\b',
  '\f': '\\f',
}
const unescapeMap = invertObj(escapeMap)
export const escapeString = (str: string): string => {
  return str.replace(/(['"\\\n\r\t\b\f])/g, (match) => escapeMap[match])
}

export const unescapeString = (str: string): string => {
  return str.replace(/\\(['"\\n\r\t\b\f])/g, (match) => unescapeMap[match])
}
