import { useConfig } from './useConfig'
import { encodeName } from '~/lib/utilities'
import { Cart, CartLine, CartPayment } from '~/types/cart'
import { GtmInjected } from '~/types/gtm'
import { Order } from '~/types/order'
import { Product } from '~/types/product'
import { WebNode } from '~/types/webNode'

export const useDatalayer = ($gtm: GtmInjected) => {
  const init = async () => {
    const { config, ensureConfig } = useConfig()
    await ensureConfig()

    const gtmId = config.value?.gtmSetting
    const enabled = config.value?.gtmEnabled

    if (enabled && gtmId) {
      $gtm.init(gtmId)
    }
  }

  const clearEcommerce = () => {
    $gtm.push({
      ecommerce: null
    });

  }

  //
  // Product
  //
  const convertProductBase = (product: Product, webNode?: WebNode) => {
    webNode ??= product.webNode
    return {
      item_id: product.id,
      item_name: encodeName(product.name),
      item_brand: encodeName(product.brand?.name),
      item_category: encodeName(webNode?.name),
      item_list_name: encodeName(webNode?.name),
      item_list_id: webNode?.id,
    }
  }

  const convertProduct = (product: Product, webNode?: WebNode) => {
    return {
      ...convertProductBase(product, webNode),
      price: product.priceInclTax,
      quantity: 1
    }
  }

  const onSearch = (term: string) => {
    clearEcommerce()
    $gtm.push({
      event: 'search',
      ecommerce: {
        search_term: term
      }
    })
  }

  const onViewProductList = (products: Product[], webNode?: WebNode) => {
    clearEcommerce()
    $gtm.push({
      event: 'view_item_list',
      ecommerce: {
        items: products.map((product, index) => ({
          ...convertProduct(product, webNode),
          index: index + 1,
          quantity: 1
        }))
      }
    })
  }

  const onClickProduct = (product: Product, index: Number) => {
    clearEcommerce()
    $gtm.push({
      event: 'select_item',
      ecommerce: {
        items: [{
          ...convertProduct(product),
          index,
          quantity: 1
        }]
      }
    })
  }

  const onViewProductDetails = (product: Product) => {
    clearEcommerce()
    $gtm.push({
      event: 'view_item',
      ecommerce: {
        items: [{
          ...convertProduct(product),
          quantity: 1
        }]
      }
    })
  }

  //
  // Cart
  //
  const convertCartLine = (line: CartLine) => {
    return {
      ...convertProductBase(line.product as Product),
      price: line.priceInclTax,
      quantity: line.quantity
    }
  }

  const onAddProducts = (lines: CartLine[]) => {
    clearEcommerce()
    $gtm.push({
      event: 'add_to_cart',
      ecommerce: {
        items: lines.map(line => convertCartLine(line))
      }
    })
  }

  const onRemoveProducts = (lines: CartLine[]) => {
    clearEcommerce()
    $gtm.push({
      event: 'remove_from_cart',
      ecommerce: {
        items: lines.map(line => convertCartLine(line))
      }
    })
  }

  const onViewCart = (cart: Cart) => {
    clearEcommerce()
    $gtm.push({
      event: 'view_cart',
      ecommerce: {
        value: cart.totalInclTax,
        items: cart.lines.map(line => ({
          ...convertCartLine(line),
          discount: line.discountInclTax,

          currency: 'EUR',
          affiliation: '',
          coupon: '',
          location_id: '',
        })),

        currency: 'EUR',
      }
    });
  }

  //
  // Checkout
  //

  const convertCheckout = (cart: Cart) => {
    return {
      value: cart.totalInclTax,
      items: cart.lines.map(line => ({
        ...convertCartLine(line),

        affiliation: '',
        coupon: '',
        currency: 'EUR',
      })),

      currency: 'EUR',
      coupon: '',
    }
  }

  const onBeginCheckout = (cart: Cart) => {
    clearEcommerce()
    $gtm.push({
      event: 'begin_checkout',
      ecommerce: convertCheckout(cart)
    })
  }

  const onSetShipment = (cart: Cart) => {
    clearEcommerce()
    $gtm.push({
      event: 'add_shipping_info',
      ecommerce: {
        ...convertCheckout(cart),

        shipping_tier: cart.shippingMethodType // Todo: shipping carrier?
      }
    })
  }

  const onSetPayment = (cart: Cart, payment: CartPayment) => {
    clearEcommerce()
    $gtm.push({
      event: 'add_payment_info',
      ecommerce: {
        ...convertCheckout(cart),

        payment_type: payment.method
      }
    })
  }

  const onPaid = (order: Order) => {
    clearEcommerce()
    $gtm.push({
      event: "purchase",
      ecommerce: {
        transaction_id: order.orderNumber,
        affiliation: encodeName(order.store?.name) ?? order.store?.id,
        value: order.totalInclTax?.toFixed(2),
        tax: (order.totalInclTax - order.totalExclTax).toFixed(2),
        shipping: order.shippingPrice?.toFixed(2),
        coupon: order.voucherTotal > 0
            ? order.voucherTotal.toFixed(2)
            : undefined,
        items: order.orderLines.map(line => ({
          ...convertProductBase(line.product),
          price: line.priceInclTax?.toFixed(2),
          quantity: line.quantity
        })),

        currency: 'EUR',
        email:  order.customer?.email || "geen email adres bekend",
        phone_number: order.customer?.phoneNumber || "geen telefoonnummer bekend"
      }
    })
  }


  return {
    init,

    onSearch,
    onViewProductList,
    onClickProduct,
    onViewProductDetails,

    onAddProducts,
    onRemoveProducts,
    onViewCart,

    onBeginCheckout,
    onSetShipment,
    onSetPayment,
    onPaid
  }

}
