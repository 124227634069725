import { useContext, ref, computed } from '@nuxtjs/composition-api'
import { useLoading } from '../ui/useLoading'
import { ProductSelection, ProductPageRequest } from '~/types/product'

const productSelections = ref<ProductSelection[]>([])
const wishSelections = ref<ProductSelection[]>([])
const getFirstSectionsLoading = useLoading()
const getAllSectionsLoading = useLoading()
const getMoreSelectionsLoading = useLoading()
const addProductSelectionLoading = useLoading()
const removeProductSelectionLoading = useLoading()
const clearProductSelectionsLoading = useLoading()

export const useProductSelections = () => {
  const {
    app: { $api },
  } = useContext()
  const offset = ref(0)
  const limit = ref(10)
  const hasMore = ref(true)

  const productSelectionsMap = computed(() => {
    return new Map(
      productSelections.value.map((productSelection) => [
        productSelection.productId,
        productSelection,
      ])
    )
  })

  const wishSelectionsMap = computed(() => {
    return new Map(
      wishSelections.value.map((productSelection) => [
        productSelection.productId,
        productSelection,
      ])
    )
  })

  const getProductSelections = async (payload?: ProductPageRequest) => {
    const defaultParams = {
      offset: offset.value,
      limit: limit.value,
    }
    const params = Object.assign(defaultParams, payload)
    const result = await $api.product.getProductSelections(params)
    hasMore.value = result.length >= limit.value
    return result
  }

  const getAllSelections = () => {
    return getAllSectionsLoading.scope(async () => {
      wishSelections.value = await getProductSelections({
        offset: 0,
        limit: 1000,
      })
    })
  }

  const getFirstSelections = () => {
    return getFirstSectionsLoading.scope(async () => {
      productSelections.value = await getProductSelections({
        fetchExtraFields: true,
        realtimeStock: true,
      })
    })
  }

  const getMoreProductSelections = () => {
    return getMoreSelectionsLoading.scope(async () => {
      offset.value += limit.value
      const moreSelections = await getProductSelections({
        fetchExtraFields: true,
        realtimeStock: true,
      })
      if (moreSelections.length) {
        productSelections.value.push(...moreSelections)
      }
    })
  }

  const getProductSelection = (productId: number) => {
    return productSelectionsMap.value.get(productId) ?? null
  }

  const getWishSelection = (productId: number) => {
    return wishSelectionsMap.value.get(productId) ?? null
  }

  const addProductSelection = (productId: number) => {
    return addProductSelectionLoading.scope(async () => {
      const result = await $api.product.addProductSelection(productId)
      if (!result) return

      productSelections.value.push(result)
      wishSelections.value.push(result)
    })
  }

  const removeProductSelection = (productSelectionId: number) => {
    return removeProductSelectionLoading.scope(async () => {
      const result = await $api.product.removeProductSelection(
        productSelectionId
      )
      if (!result) return

      const wishIndex = wishSelections.value.findIndex(
        (productSelection) => productSelection.id === productSelectionId
      )
      if (wishIndex === -1) return
      wishSelections.value.splice(wishIndex, 1)

      const index = productSelections.value.findIndex(
        (productSelection) => productSelection.id === productSelectionId
      )
      if (index === -1) return
      productSelections.value.splice(index, 1)
    })
  }

  const clearProductSelections = () => {
    return clearProductSelectionsLoading.scope(async () => {
      const result = await $api.product.clearProductSelections()
      if (result) {
        productSelections.value = []
        wishSelections.value = []
      }
      return result
    })
  }

  return {
    getAllSectionsLoading: getAllSectionsLoading.value,
    getFirstSectionsLoading: getFirstSectionsLoading.value,
    getMoreSelectionsLoading: getMoreSelectionsLoading.value,
    addProductSelectionLoading: addProductSelectionLoading.value,
    removeProductSelectionLoading: removeProductSelectionLoading.value,
    clearProductSelectionsLoading: clearProductSelectionsLoading.value,
    productSelections,
    productSelectionsMap,
    hasMore,
    wishSelections,

    getProductSelection,
    getFirstSelections,
    getMoreProductSelections,
    addProductSelection,
    removeProductSelection,
    clearProductSelections,
    getAllSelections,
    getWishSelection,
  }
}
