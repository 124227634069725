var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quantity-input inline-flex border-2 rounded-full border-gw-secondary relative",class:[
    _vm.readonly
      ? 'pointer-events-none bg-black bg-opacity-10 opacity-60'
      : 'bg-white',
    {
      'w-full': _vm.block,
      'pr-5 h-10': _vm.contronlPosition !== 'default',
      'h-12': _vm.contronlPosition === 'default',
    } ]},[(!_vm.readonly)?_c('button',{staticClass:"btn flex items-center justify-center flex-shrink-0",class:[
      _vm.contronlPosition === 'default'
        ? 'w-[44px] h-[44px] rounded-full hover:bg-gw-grey-300 border-2 border-white transition-colors'
        : 'w-5 h-5 bg-gw-primary absolute bottom-0 right-0 border-t border-white rounded-br-lg' ],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDecrease.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"text-black",class:[_vm.contronlPosition === 'default' ? 'w-5 h-5' : 'w-3 h-3'],attrs:{"name":_vm.contronlPosition === 'default' ? 'gw-remove' : 'gw-remove'}})],1):_vm._e(),_vm._v(" "),_c('input',{ref:"inputEl",staticClass:"focus:outline-none appearance-none-number text-center text-black w-full outline-none bg-transparent",class:[
      _vm.contronlPosition === 'default'
        ? 'h-12'
        : 'h-10' ],attrs:{"type":_vm.inputType,"maxlength":_vm.maxLength,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":function($event){_vm.isFocus = false},"change":function($event){_vm.check(Number($event.target.value))},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.onIncrease.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.onDecrease.apply(null, arguments)}]}}),_vm._v(" "),(!_vm.readonly)?_c('button',{staticClass:"btn flex items-center justify-center flex-shrink-0",class:[
      _vm.contronlPosition === 'default'
        ? 'w-[44px] h-[44px] rounded-full hover:bg-gw-grey-300 border-2 border-white transition-colors'
        : 'w-5 h-5 bg-gw-primary absolute top-0 right-0 border-b border-white rounded-tr-lg' ],attrs:{"type":"button","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onIncrease.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"text-black",class:[_vm.contronlPosition === 'default' ? 'w-5 h-5' : 'w-3 h-3'],attrs:{"name":_vm.contronlPosition === 'default' ? 'gw-add' : 'gw-add'}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }