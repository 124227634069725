import { Dictionary } from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import {
  Product,
  ProductConnectionType,
  ProductListOptions,
  ProductReview,
  ProductSelection,
  ProductStock,
  ProductPageRequest,
  ProductStockRefresh,
} from '@/types/product'
import {
  convertProduct,
  convertProductAvailibility,
  convertProductReviews,
  convertProducts,
  convertProductSelection,
  convertProductSelections,
  convertProductStock,
  convertStock,
} from '~/lib/api/deserializers/product'
import { convertWebNode } from '~/lib/api/deserializers/webNode'
import { ReviewSorting } from '~/composables/product/useProductReview'

enum ReviewSortField {
  CustomerRating = 'CustomerRating',
  CreationDate = 'CreationDate',
}

const SORT_BY_PARAMS: Readonly<
  Record<
    ReviewSorting,
    {
      field: ReviewSortField
      ascending: boolean
    }
  >
> = {
  [ReviewSorting.NewestFirst]: {
    field: ReviewSortField.CreationDate,
    ascending: false,
  },
  [ReviewSorting.OldestFirst]: {
    field: ReviewSortField.CreationDate,
    ascending: true,
  },
  [ReviewSorting.MostStars]: {
    field: ReviewSortField.CustomerRating,
    ascending: false,
  },
  [ReviewSorting.FewestStars]: {
    field: ReviewSortField.CustomerRating,
    ascending: true,
  },
}

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/products'

  return {
    async getListOptions(): Promise<ProductListOptions | null> {
      const response: AxiosResponse = await instance.get(
        `/${base}/elastic-search/config`
      )
      if (!response.data.Success) return null

      const data = response.data.Data

      const facetLabels = data.FacetTitleOverride
      const sortOptions = data.Sorting.map((item: any) => ({
        label: item.Title,
        field: item.FieldName,
      }))

      return {
        limit: data.MaximumProdCountToShowDedicatedCategoryPage || 12,
        facetLabels,
        sortOptions,
      }
    },

    async getByUrl(
      url: string,
      currentWebNodeId?: number
    ): Promise<Product | null> {
      const response: AxiosResponse = await instance.get(
        `/${base}/url/${encodeURIComponent(url)}`,
        {
          params: {
            includingWebNode: true,
            realtimeStock: true,
            ...(currentWebNodeId ? { webNodeId: currentWebNodeId } : {}),
          },
        }
      )

      if (!response.data.Success) return null

      const product = convertProduct(response.data.Product, {
        url: response.data.ProductUrl,
        ...(response.data.ExtraFields ?? {}),
      })
      if (response.data.WebNode) {
        product.webNode = convertWebNode(response.data.WebNode)
      }

      return product
    },

    async getByIds(productIds: string): Promise<Product[] | []> {
      const response: AxiosResponse = await instance.get(
        `/${base}/ids?productIds=${productIds}`,
        {
          params: {
            realtimeStock: true,
            fetchExtraFields: true,
          },
        }
      )

      if (!response.data.Success) return []

      const products = convertProducts(
        response.data.Products,
        response.data.ProductUrls,
        response.data.ExtraFields
      )

      return products
    },

    async getByWebNodeId(webNodeId: number | string): Promise<Product[] | []> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/webnodes/${webNodeId}/products`,
        {
          params: {
            fetchExtraFields: true,
          },
        }
      )

      if (!response.data.Products?.length) return []

      const products = convertProducts(
        response.data.Products,
        response.data.ProductUrls || response.data.Urls,
        response.data.ExtraFields
      )

      return products
    },

    /*
     * Product connections
     */
    async getConnections(
      productId: number,
      connectionTypes: ProductConnectionType[],
      limit = 50
    ): Promise<Dictionary<Product[]>> {
      const { data } = await instance.get(`/${base}/${productId}/connections`, {
        params: {
          productId,
          realtimeStock: true,
          fetchExtraFields: true,
          connectionTypes,
          limit,
        },
      })

      if (!data.Success) return {}

      const groups = data.Connections.reduce(
        (prev: Dictionary<Product[]>, current: any) => {
          let group = prev[current.Type]
          if (!group) {
            prev[current.Type] = group = []
          }
          const product = convertProduct(current.Product, {
            url: data.ProductUrls?.[current.Product.Id],
            ...(data.ExtraFields?.[current.Product.Id] ?? {}),
          })

          // filter out current product when ProductConnectionType is Replacement or Interesting
          if (
            current.Type === ProductConnectionType.Replacement ||
            current.Type === ProductConnectionType.Interesting
          ) {
            if (current.ProductId === productId) {
              if (current.ParentProduct) {
                const parentProduct = convertProduct(current.ParentProduct, {
                  url: data.ProductUrls?.[current.ParentProduct.Id],
                  ...(data.ExtraFields?.[current.ParentProduct.Id] ?? {}),
                })

                group.push(parentProduct)
              }
            } else {
              group.push(product)
            }
          } else {
            group.push(product)
          }
          return prev
        },
        {} as Dictionary<Product[]>
      )
      return groups
    },

    /*
     * Product reviews
     */

    async getReviews(payload: {
      productId: number
      limit?: number
      sorting?: ReviewSorting
      needRatingGroups?: boolean
    }) {
      const { productId, limit, sorting, needRatingGroups = false } = payload
      const sortBy = SORT_BY_PARAMS[sorting ?? ReviewSorting.NewestFirst]
      const response: AxiosResponse = await instance.get(
        `/${base}/${productId}/reviews`,
        {
          params: {
            sortField: sortBy?.field,
            sortAscending: sortBy?.ascending,
          },
        }
      )

      if (!response.data.Success) return null

      const { reviews, totalCount, averageRating, totalRating, ratingGroups } =
        convertProductReviews(response.data.Reviews ?? [], needRatingGroups)
      const limitedReviews =
        limit && reviews.length > limit ? reviews.slice(0, limit) : reviews

      return {
        reviews: limitedReviews,
        totalCount,
        averageRating,
        totalRating,
        ...(needRatingGroups ? { ratingGroups } : {}),
      }
    },

    async addReview(
      productId: number,
      review: ProductReview
    ): Promise<boolean> {
      let success = false
      try {
        const response: AxiosResponse = await instance.post(
          `/${base}/${productId}/reviews`,
          {
            customerRating: review.rating,
            content: review.content,
            isVisible: false,
          }
        )
        success = response.data.Success
      } catch {
        success = false
      }

      return success
    },

    /*
     * Viewed products
     */

    async addViewedProduct(
      productId: number,
      brandId?: number
    ): Promise<boolean> {
      const response: AxiosResponse = await instance.post(`/${base}/viewed`, {
        productId,
        brandId,
      })
      return response.data.Success
    },

    async getViewedProducts(): Promise<Product[]> {
      const response: AxiosResponse = await instance.get(`/${base}/viewed`, {
        params: {
          fetchExtraFields: true,
          realtimeStock: true,
        },
      })
      if (!response.data.Success) return []

      const data = response.data
      return convertProducts(data.Products, data.ProductUrls, data.ExtraFields)
    },

    /*
     * Product sections
     */

    async getProductSelections(
      payload: ProductPageRequest
    ): Promise<ProductSelection[]> {
      const defaultParams: ProductPageRequest = {
        limit: 10,
        offset: 0,
        fetchExtraFields: false,
        realtimeStock: false,
      }
      const params = Object.assign(defaultParams, payload)

      const response: AxiosResponse = await instance.get(
        `/${base}/selections`,
        {
          params,
        }
      )
      if (!response.data.Success) return []

      const data = response.data
      return convertProductSelections(
        data.ProductSelections,
        data.ProductUrls,
        data.ExtraFields
      )
    },

    async addProductSelection(
      productId: number
    ): Promise<ProductSelection | null> {
      const response: AxiosResponse = await instance.post(
        `/${base}/selections`,
        {
          productId,
        }
      )
      if (!response.data.Success) return null
      return convertProductSelection(response.data.ProductSelection)
    },

    async removeProductSelection(productSelectionId: number): Promise<boolean> {
      const response: AxiosResponse = await instance.delete(
        `/${base}/selections/${productSelectionId}`
      )
      return !!response.data?.Success
    },

    async clearProductSelections(): Promise<boolean> {
      const response: AxiosResponse = await instance.post(
        `/${base}/selections/clear`
      )
      return !!response.data?.Success
    },

    /*
     * Product stocks
     */

    async getProductStocks(productId: number): Promise<ProductStock[]> {
      const response: AxiosResponse = await instance.get(
        `/${base}/${productId}/stock`
      )
      const stocks =
        response.data?.Stock?.map((stock: any) => convertStock(stock)) ?? []

      return stocks
    },

    async getStocks(
      productIds: number[]
    ): Promise<Dictionary<ProductStockRefresh>> {
      const response: AxiosResponse = await instance.post(`/${base}/stock`, {
        ids: productIds,
        fetchExtraFields: true,
      })

      const result: Dictionary<ProductStockRefresh> = {}
      Object.keys(response.data.Products).forEach((id) => {
        const data = response.data.Products[id]
        result[id] = {
          stock: convertProductStock(data.Stock),
          availibility: convertProductAvailibility(data.Availability),
        }
      })

      return result
    },
  }
}
